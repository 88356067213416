'use client'

import './chat-window.scss'
import { KeyboardEvent, useEffect, useRef, useState } from 'react'
import SvgSend from 'app/components/Svg/SvgSend'
import { useLocalStorage } from 'app/hooks/useStorage'
import trackEngagementClick from 'app/components/Snowplow/lib/trackEngagementClick.client'
import TextArea from '../TextArea/TextArea.client'
import UserChatBubble from '../UserChatBubble/UserChatBubble.client'
import ResponseChatBubble from '../ResponseChatBubble.client'
import TypingAnimation from '../TypingAnimation/TypingAnimation'
import PopularQuestions from '../PopularQuestions/PopularQuestions.client'
import ChatModal from '../ChatModal/ChatModal'
import WelcomeChatBubble from '../WelcomeChatBubble.client'
import { useMutationObserver } from './useMutationObserver'
import { useMessageSubmit } from './useMessageSubmit'

const AI_INPUT_LIMIT = 4000

interface IChatWindowProps {
  assistantPrompt?: string
  welcomeMessage?: string
  popularQuestions?: string[]
  showModal?: boolean
  modalContent?: string
  modalTitle?: string
  modalButton?: string
}

export default function ChatWindow({
  assistantPrompt,
  welcomeMessage,
  popularQuestions,
  showModal,
  modalContent,
  modalTitle,
  modalButton,
}: IChatWindowProps) {
  const {
    onMessageSubmit,
    messageResponseIds,
    isRetrieving,
    isSubmitting,
    setInputValue,
    inputValue,
    messages,
  } = useMessageSubmit()
  const formRef = useRef<HTMLFormElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const messageViewRef = useMutationObserver()
  const [accessibleMessage, setAccessibleMessage] = useState('')

  useEffect(() => {
    if (!isRetrieving) {
      const lastResponse = messages.filter((m) => m.role === 'assistant').at(-1)
      setAccessibleMessage(lastResponse?.content || '')
      const timeout = setTimeout(() => {
        setAccessibleMessage('')
      }, 5000)

      return () => clearTimeout(timeout)
    }

    let timeout: NodeJS.Timeout

    const statuses = [
      'The Assistant is thinking...',
      'The Assistant is typing...',
      'The Assistant is still typing..',
    ]

    let timesRun = 1
    setAccessibleMessage(statuses[0])
    const interval = setInterval(() => {
      setAccessibleMessage('')
      timeout = setTimeout(() => {
        setAccessibleMessage(
          `${statuses[Math.min(timesRun, statuses.length - 1)]}${Array(timesRun)
            .fill('.')
            .reduce((acc, curr) => `${acc}${curr}`)}`
        )
        timesRun += 1
      }, 50)
    }, 5000)

    return () => {
      clearTimeout(timeout)
      clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRetrieving])

  const onInputKeyPress = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      formRef.current?.requestSubmit(buttonRef.current)
    }
  }

  const userHasInteracted = messages.length || isRetrieving
  const [isAccepted, setIsAccepted] = useLocalStorage(
    'disclaimer_accepted',
    false
  )

  if (showModal && !isAccepted) {
    return (
      <ChatModal
        onClose={() => {
          setIsAccepted(true)
        }}
        modalTitle={modalTitle}
        modalContent={modalContent}
        modalButton={modalButton}
      />
    )
  }

  return (
    <div className='chatWindow' data-testid='chat-window'>
      <div ref={messageViewRef} className='chatWindow__messageView'>
        <WelcomeChatBubble
          className={`chatWindow__welcomeMessage
            ${!assistantPrompt && 'chatWindow__welcomeMessage--largeMargin'}`}
        >
          {welcomeMessage}
        </WelcomeChatBubble>

        {assistantPrompt && (
          <WelcomeChatBubble className='chatWindow__assistantPrompt'>
            {assistantPrompt}
          </WelcomeChatBubble>
        )}

        {messages.map((m, index) => {
          if (m.role === 'assistant') {
            return (
              <ResponseChatBubble
                key={m.id}
                responseId={messageResponseIds[m.id]}
                hideActionBlock={index === messages.length - 1 && isRetrieving}
              >
                {m.content}
              </ResponseChatBubble>
            )
          }

          return <UserChatBubble key={m.id}>{m.content}</UserChatBubble>
        })}

        <TypingAnimation showAnimation={isSubmitting} />
      </div>
      <div className='chatWindow__bottomSection'>
        <div aria-live='assertive' aria-atomic='true' className='sr-only'>
          {accessibleMessage}
        </div>
        {!userHasInteracted && popularQuestions?.length && (
          <PopularQuestions
            onSelect={onMessageSubmit}
            questions={popularQuestions || []}
          />
        )}

        <div>
          <form
            ref={formRef}
            className={`chatWindow__inputForm ${isRetrieving && 'chatWindow__inputForm--disabled'}`}
            onSubmit={(e) => {
              e.preventDefault()
              trackEngagementClick({
                unit_name: 'conversation',
                unit_location: 'inline',
                component_id: null,
                submitted_object: 'written_query',
              })
              onMessageSubmit()
            }}
          >
            <label
              htmlFor='chat-window-input'
              className='sr-only chatWindow__inputForm__label'
            >
              Message the Assistant...
            </label>
            <TextArea
              id='chat-window-input'
              className='chatWindow__inputForm__input'
              data-testid='chat-window-input'
              placeholder='Message the Assistant...'
              maxLength={AI_INPUT_LIMIT}
              onChange={(e) => setInputValue(e.target.value)}
              value={inputValue}
              rows={1}
              autoFit
              aria-disabled={isRetrieving}
              onKeyDown={onInputKeyPress}
            />
            <button
              ref={buttonRef}
              type='submit'
              className='chatWindow__inputForm__button'
              data-testid='chat-window-submit'
              aria-disabled={!inputValue.length || isRetrieving}
              disabled={!inputValue.length || isRetrieving}
              aria-label='Send message'
            >
              <SvgSend className='chatWindow__inputForm__button__icon' />
            </button>
          </form>
          {inputValue.length >= AI_INPUT_LIMIT * 0.8 && (
            <div>{`${inputValue.length}/${AI_INPUT_LIMIT}`}</div>
          )}
        </div>
      </div>
    </div>
  )
}
