import { useEffect, useState } from 'react'
import { Message } from 'ai/react'
import { v4 as uuidv4 } from 'uuid'
import { generateId } from 'ai'
import { readStreamableValue } from 'ai/rsc'
import { EndpointsContext } from 'app/ai/agent'
import { RunnableConfigWithMetadata } from 'app/ai/types'
import { useActions } from '../AIProvider/AIProvider.client'

export function useMessageSubmit() {
  const { agent } = useActions<typeof EndpointsContext>()
  const [messages, setMessages] = useState<Message[]>([])
  const [inputValue, setInputValue] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isRetrieving, setIsRetrieving] = useState(false)
  const [messageResponseIds, setMessageResponseIds] = useState<
    Record<string, string>
  >({})

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      sessionStorage.removeItem('conversation_id')
    }
  }, [])

  const onInputChange = (value: string) => {
    if (isRetrieving) return
    setInputValue(value)
  }

  const onMessageSubmit = async (value?: string) => {
    const input = value || inputValue
    if (isSubmitting || isRetrieving || !input.length) return

    const history = messages
    const messageId = generateId()

    setMessages((prev) => [
      ...prev,
      { id: `user-${messageId}`, role: 'user', content: input },
    ])
    setInputValue('')
    setIsSubmitting(true)
    setIsRetrieving(true)

    // Note: We could also use useState, but that would be challenging to test
    const storedConversationId = sessionStorage.getItem('conversation_id') || ''
    const conversationId = storedConversationId || uuidv4()
    if (!storedConversationId) {
      sessionStorage.setItem('conversation_id', conversationId)
    }
    const responseId = uuidv4()
    const config: RunnableConfigWithMetadata = {
      runId: responseId,
      metadata: {
        response_id: responseId,
        conversation_id: conversationId,
      },
    }

    const { text } = await agent({
      input,
      chat_history: history,
      config,
    })

    let textContent = ''

    // eslint-disable-next-line no-restricted-syntax
    for await (const delta of readStreamableValue(text)) {
      textContent = `${textContent}${delta}`

      if (textContent) {
        setIsSubmitting(false)
        // eslint-disable-next-line @typescript-eslint/no-loop-func
        setMessages((prev) => {
          const response: Message = {
            id: messageId,
            role: 'assistant',
            content: textContent,
          }

          const existingMessageIndex = prev.findIndex((m) => m.id === messageId)
          if (existingMessageIndex !== -1) {
            return [
              ...prev.slice(0, existingMessageIndex),
              response,
              ...prev.slice(existingMessageIndex + 1),
            ]
          }

          return [...prev, response]
        })
      }
    }

    setIsRetrieving(false)
    setMessageResponseIds({
      ...messageResponseIds,
      [messageId]: responseId,
    })
  }

  return {
    inputValue,
    setInputValue: onInputChange,
    isSubmitting,
    messageResponseIds,
    onMessageSubmit,
    isRetrieving,
    messages,
  }
}
